// Modules
import { faLongArrowAltLeft } from '@fortawesome/free-solid-svg-icons'
import PropTypes from 'prop-types'
import React from 'react'
import styled from '@emotion/styled'
import {
  graphql,
  Link,
} from 'gatsby'

// Components
import Container from 'components/global-components/Container'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Helmet } from 'react-helmet'
import Layout from 'components/global-components/Layout'

// Scripts
import { largeUp } from 'scripts/media-query'

// Theme
import theme from 'scripts/theme'

// PropTypes
const propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.arrayOf(PropTypes.object),
      totalCount: PropTypes.number.isRequired,
    }),
  }),
  pageContext: PropTypes.shape({ tag: PropTypes.string.isRequired }),
}

const TagRoute = ({
  data,
  pageContext,
}) => {
  const {
    allMarkdownRemark: {
      edges: posts,
      totalCount,
    },
  } = data

  const { tag } = pageContext
  const tagHeader = `${totalCount} Post${
    totalCount === 1 ? '' : 's'
  } Tagged with “${tag}”`

  const TagRouteStyles = styled.section`
    h1 { text-align: center; }

    .tags-wrapper {
      position: relative;
      display: flex;
      flex-direction: column;
    }
    
    .tag-page-link {
      position: absolute;
      top: -2.25rem;

      ${largeUp} { top: -3rem; }

      a { display: block; }
    }

    .tag-list {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      margin: 0 auto;

      li {
        width: 100%;
        margin-bottom: 2rem;
      }
    }

    .post-heading,
    .post-date {
      display: block;
    }

    .post-heading {
      color: ${theme.color.primary};
      font-weight: 700;
    }

    .post-date { font-size: .75rem; }
  `

  return (
    <Layout hasNoHero>
      <Helmet title={`${tag} Tag`} />
      <TagRouteStyles>
        <Container>
          <div className='tags-wrapper'>
            <div className='tag-page-link'>
              <Link to='/blog/tags/'>
                <FontAwesomeIcon icon={faLongArrowAltLeft} />
                {' Back to all tags'}
              </Link>
            </div>
            <h1>{tagHeader}</h1>
            <ul className='tag-list'>
              {
                posts.map(post => {
                  const {
                    node: {
                      fields: { slug },
                      frontmatter: {
                        date,
                        postData: { postHeading },
                      },
                    },
                  } = post

                  return (
                    <li key={slug}>
                      <Link to={slug}>
                        <span className='post-heading'>{postHeading}</span>
                        <span className='post-date'>{date}</span>
                      </Link>
                    </li>
                  )
                })
              }
            </ul>
          </div>
        </Container>
      </TagRouteStyles>
    </Layout>
  )
}

TagRoute.propTypes = propTypes
export default TagRoute
export const tagPageQuery = graphql`
  query TagPage($tag: String) {
    allMarkdownRemark(
      limit: 1000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MM/DD/YYYY")
            postData {
              postHeading
            }
          }
        }
      }
    }
  }
`
